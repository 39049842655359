/**
 * AcerteJá Pages
 *
 * @author Bruno Alano Medina
 * @license Closed Source Software
 */

.container, .side-nav + div {
  width: 100%;
  margin-left: 0;
  transition: .11s ease;
  position: relative;
  min-height: calc(100vh - 48px);
  margin-top: 3rem;
  display: flex;
  flex-flow: column;
}

.container {
  .bx--grid {
    margin: 0;
    max-width: 100%;
  }
}

.content-with-sidebar {
  height: 100%;
}
.content-with-sidebar .bx--grid, .content-with-sidebar .bx--grid > .bx--row { height: 100%; }

.acerteja-content-sidebar {
  padding-top: 2rem;
  border-right: 1px solid #f3f3f3;
  flex: 2 ;
}

.acerteja-sidebar-next-content {
  padding-top: 2rem;
  padding-left: 2rem;
  flex: 2 ;
}
