.exception {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
  margin-top: 10%;
}
.exception .imgBlock {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.exception .imgBlock::before,
.exception .imgBlock::after {
  display: table;
  content: ' ';
}
.exception .imgBlock::after {
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden;
}
.exception .imgEle {
  float: right;
  width: 100%;
  max-width: 430px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.exception .content {
  flex: auto;
}
.exception .content h1 {
  margin-bottom: 24px;
  color: #434e59;
  font-weight: 600;
  font-size: 72px;
  line-height: 72px;
}
.exception .content .desc {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
}
.exception .content .actions button:not(:last-child) {
  margin-right: 8px;
}
