/**
 * Carbon Design System Overrides
 *
 * @author Bruno Alano Medina
 * @license Closed Source Software
 */

.bx--form-item,
.bx--form,
.bx--fieldset {
  width: 100% !important;
}
.bx--form-item {
  margin-bottom: 0.5rem;
}
.bx--inline-loading__text {
  margin-bottom: 0 !important;
}
.bx--form-requirement {
  max-height: 12.5rem;
}

.ant-select-dropdown,
.ant-select-dropdown--single,
.ant-calendar-picker-container {
  z-index: 100000000;
}

.bx--modal-content {
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-fullcalendar-date {
  &:hover,
  &:active {
    cursor: default !important;
  }
}

.ant-drawer {
  margin-top: 48px;
  z-index: 10000000;
}
.ant-drawer-content-wrapper {
  padding-bottom: 48px;
}

.campaign-form .ant-row {
  width: 100%;
}

.ant-tooltip,
.ant-popover,
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1000000 !important;
}

.ant-calendar-picker-input.ant-input {
  line-height: 1.5 !important;
}

.bx--header__global .bx--header__submenu .bx--header__menu {
  max-height: 90vh;
  overflow-y: scroll;
}

.custom-copy {
  display: inline-block !important;
  margin-right: 10px !important;

  .ant-typography-copy {
    margin-left: 0 !important;
  }
}

.page-header {
  background-color: #ebebeb !important;
  color: #8b8b8b !important;
}

.bx--header {
  background-color: #272727;
}

.ant-btn-primary {
  background-color: #f87a01;
  border-color: #f87a01;
}

a.bx--header__name,
a.bx--header__name:hover {
  font-size: 1rem;
  color: #f87a01;
  padding-right: 1rem;

  span {
    color: #8b8b8b;
  }
}

.page-header .page-tabs ul li a {
  background: #3d3d3d;
}

.ant-notification {
  z-index: 100000;
}

div.ant-typography-edit-content {
  left: 0px !important;
  margin-right: 15px;
}

div.ant-typography-edit-content textarea { resize: none !important }

.whatsapp-integration-card {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.whatsapp-integration-card > .ant-card-body {
  padding: 0 !important;
  flex: 1;
}

/* Notification Sidebar */
.bx--header-panel--expanded {
  width: 25rem;
}

.bx--header-panel .bx--inline-loading__text {
  color: #fff;
}

.bx--inline-notification__details, .bx--inline-notification__text-wrapper {
  width: 100%;
}

.bx--header .bx--btn--ghost {
  padding: 0.375rem .5rem;
}

.bx--header-panel {
  overflow-y: auto;
}

.bx--header-panel::-webkit-scrollbar {
  display: none;
}

.task-list-item {
  display: flex;
  .ant-list-item-action {
    margin-left: auto;
  }
}

.task-delete-icon {
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  .anticon-close {
    color: rgba(0,0,0,.45);
    transition: color .3s;
    &:hover {
      color: rgba(0,0,0,.75);
    }
  }
}

.notification-menu-badge {
  position: absolute;
  background: #f87a01;
  width: 15px;
  display: inline-block;
  margin-left: -8px;
  margin-top: -8px;
  border-radius: 15px;
  color: #fff;
}

.quill {
  width: 100%;
}

.bx--text-input--sm {
  height: 2rem;
}

.subtable-title {
  @include type-style("productive-heading-02");
}

.bx--header-panel {
  .bx--inline-notification__subtitle {
    margin-left: 10px;
    flex: 1;

    a {
      font-size: 0.725rem;
      line-height: 1rem;
    }
  }
}

.notification-small-txt {
  @include type-style("helper-text-01");
  font-style: normal;
  font-size: 0.725rem;
  line-height: 1rem;

  border-right: 1px solid rgba(255,255,255,.3);
  padding-right: 3px;
  margin-right: 3px;
}