.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3f2f2;
  height: 100vh;
}
.signin-container form {
  padding: 40px 30px;
  background: #fefefe;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  width: 300px;
}
.signin-container form h4 {
  font-size: 28px;
  font-weight: 600;
  opacity: 0.85;
  margin-bottom: 20px;
  color: #757575;
}
.signin-container form h4 span {
  color: #f87a01;
  font-weight: 700;
}
.signin-container form p {
  line-height: 155%;
  margin-bottom: 5px;
  font-size: 14px;
  color: #757575;
  opacity: 0.65;
  font-weight: 400;
  max-width: 200px;
  margin-bottom: 40px;
}
.session {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  margin: auto auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
}
.left {
  width: 220px;
  height: auto;
  min-height: 100%;
  position: relative;
  background-image: url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-size: cover;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.left svg {
  height: 40px;
  width: auto;
  margin: 20px;
}
