.page-header {
  background: #000;
  color: #000;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 15rem;

  .bx--link,
  .bx--breadcrumb-item::after {
    color: #000;
  }

  h1.page-title {
    @include type-style("productive-heading-05");
    margin-bottom: 1.5rem;
    margin-top: auto;
  }

  .page-tabs,
  ul {
    width: 100%;
    height: 3rem;
    position: relative;
  }

  .page-tabs {
    ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-self: center;

        a {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.375rem;
          letter-spacing: 0;
          padding: 0 3rem 0 1rem;
          text-decoration: none;
          color: #fff;
          white-space: nowrap;
          border-top: 3px solid transparent;
          height: 3rem;
          display: -webkit-inline-flex;
          display: inline-flex;
          -webkit-align-items: center;
          align-items: center;
          transition: 0.11s;

          &:hover {
            background: #353535;
          }

          &.selected {
            border-top: 3px solid #0062ff;
            background: #3d3d3d;
          }
        }
      }
    }
  }

  .page-tabs {
    margin: 0;
    box-shadow: 0 -1px 0 hsla(0, 0%, 100%, 0.25);
    z-index: 10;
  }
}
