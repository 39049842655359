.container {
  padding: 10px 15px;
}

.subcontainer {
  padding: 10px;
  margin-bottom: 25px;
}

.subcontainer_title {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  letter-spacing: 0px;
  margin-bottom: 15px;
  color: #fff;
}